export default {
    'header': { slotId: '22990353741', name: '71161633/PRNNSPG_pronounspage/header' },

    'content-0': { slotId: '22990470037', name: '71161633/PRNNSPG_pronounspage/footer' },
    'content-1': { slotId: '22990353738', name: '71161633/PRNNSPG_pronounspage/incontent_2' },
    'content-footer': { slotId: '22990470040', name: '71161633/PRNNSPG_pronounspage/blog_footer' },
    'content-mobile-0': { slotId: '22990353744', name: '71161633/PRNNSPG_pronounspage/mrec_1' },
    'content-mobile-1': { slotId: '22990470043', name: '71161633/PRNNSPG_pronounspage/mrec_2' },
    'content-mobile-2': { slotId: '22990470046', name: '71161633/PRNNSPG_pronounspage/mrec_3' },

    'aside-left': { slotId: '22990470034', name: '71161633/PRNNSPG_pronounspage/vrec_lhs', sticky: true },
    'aside-right': { slotId: '22990470031', name: '71161633/PRNNSPG_pronounspage/vrec_rhs', sticky: true },

    'footer': { slotId: '22990470028', name: '71161633/PRNNSPG_pronounspage/incontent_1' },
};


if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace('')
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as abuseReports8sMqIHd8e0Meta } from "/home/admin/www/en.pronouns.page/release/20250125130135/pages/admin/abuseReports.vue?macro=true";
import { default as _91id_939vP8zGuBCcMeta } from "/home/admin/www/en.pronouns.page/release/20250125130135/pages/admin/audit-log/[username]/[id].vue?macro=true";
import { default as editorFkK4pQj7sDMeta } from "/home/admin/www/en.pronouns.page/release/20250125130135/pages/admin/blog/editor.vue?macro=true";
import { default as indextV46RsEspGMeta } from "/home/admin/www/en.pronouns.page/release/20250125130135/pages/admin/index.vue?macro=true";
import { default as moderation0SBtbwvvm7Meta } from "/home/admin/www/en.pronouns.page/release/20250125130135/pages/admin/moderation.vue?macro=true";
import { default as pendingBans0pyDVnMQYZMeta } from "/home/admin/www/en.pronouns.page/release/20250125130135/pages/admin/pendingBans.vue?macro=true";
import { default as profilesSbogCPLiGgMeta } from "/home/admin/www/en.pronouns.page/release/20250125130135/pages/admin/profiles.vue?macro=true";
import { default as expenseshXhtyO1Xj6Meta } from "/home/admin/www/en.pronouns.page/release/20250125130135/pages/admin/timesheets/expenses.vue?macro=true";
import { default as indexukNUlm1Br3Meta } from "/home/admin/www/en.pronouns.page/release/20250125130135/pages/admin/timesheets/index.vue?macro=true";
import { default as overviewEsi5LQwiIdMeta } from "/home/admin/www/en.pronouns.page/release/20250125130135/pages/admin/timesheets/overview.vue?macro=true";
import { default as awaiting9nVjcMlExMMeta } from "/home/admin/www/en.pronouns.page/release/20250125130135/pages/admin/translations/awaiting.vue?macro=true";
import { default as missingrHcnGhkKsXMeta } from "/home/admin/www/en.pronouns.page/release/20250125130135/pages/admin/translations/missing.vue?macro=true";
import { default as usersMSy6F4oR2nMeta } from "/home/admin/www/en.pronouns.page/release/20250125130135/pages/admin/users.vue?macro=true";
import { default as apilBQJDD1AHQMeta } from "/home/admin/www/en.pronouns.page/release/20250125130135/pages/api.vue?macro=true";
import { default as _91slug_93ShjLZNosKLMeta } from "/home/admin/www/en.pronouns.page/release/20250125130135/pages/blog/[slug].vue?macro=true";
import { default as indexUhdkr9wSsZMeta } from "/home/admin/www/en.pronouns.page/release/20250125130135/pages/blog/index.vue?macro=true";
import { default as _91_91year_93_93U3nSLG0dXoMeta } from "/home/admin/www/en.pronouns.page/release/20250125130135/pages/calendar/[[year]].vue?macro=true";
import { default as _91year_93_45_91month_93_45_91day_93NnsiMpTneUMeta } from "/home/admin/www/en.pronouns.page/release/20250125130135/pages/calendar/[year]-[month]-[day].vue?macro=true";
import { default as adminzbsBGL7XHuMeta } from "/home/admin/www/en.pronouns.page/release/20250125130135/pages/census/admin.vue?macro=true";
import { default as indexghxKOSlvblMeta } from "/home/admin/www/en.pronouns.page/release/20250125130135/pages/census/index.vue?macro=true";
import { default as contact1zE4uxC9EOMeta } from "/home/admin/www/en.pronouns.page/release/20250125130135/pages/contact.vue?macro=true";
import { default as designpCIFsmFSZMMeta } from "/home/admin/www/en.pronouns.page/release/20250125130135/pages/design.vue?macro=true";
import { default as englishrzZR4lRlbxMeta } from "/home/admin/www/en.pronouns.page/release/20250125130135/pages/english.vue?macro=true";
import { default as faqz3FaN47DC3Meta } from "/home/admin/www/en.pronouns.page/release/20250125130135/pages/faq.vue?macro=true";
import { default as inclusive93ZqQwai4nMeta } from "/home/admin/www/en.pronouns.page/release/20250125130135/pages/inclusive.vue?macro=true";
import { default as index_45homec33TGfgjZgMeta } from "/home/admin/www/en.pronouns.page/release/20250125130135/pages/index-home.vue?macro=true";
import { default as index_45locale9s0XoSTPg5Meta } from "/home/admin/www/en.pronouns.page/release/20250125130135/pages/index-locale.vue?macro=true";
import { default as langswitchMIlFiFOrWTMeta } from "/home/admin/www/en.pronouns.page/release/20250125130135/pages/langswitch.vue?macro=true";
import { default as licenseXKT3Q4Vtc5Meta } from "/home/admin/www/en.pronouns.page/release/20250125130135/pages/license.vue?macro=true";
import { default as academicrmwLAiqWMpMeta } from "/home/admin/www/en.pronouns.page/release/20250125130135/pages/links/academic.vue?macro=true";
import { default as index0XXtVHuE6rMeta } from "/home/admin/www/en.pronouns.page/release/20250125130135/pages/links/index.vue?macro=true";
import { default as media6ffh8iCvibMeta } from "/home/admin/www/en.pronouns.page/release/20250125130135/pages/links/media.vue?macro=true";
import { default as translinguisticsW3alNdhmApMeta } from "/home/admin/www/en.pronouns.page/release/20250125130135/pages/links/translinguistics.vue?macro=true";
import { default as zineVEwq5gmxdSMeta } from "/home/admin/www/en.pronouns.page/release/20250125130135/pages/links/zine.vue?macro=true";
import { default as nameshwUGvpBsW6Meta } from "/home/admin/www/en.pronouns.page/release/20250125130135/pages/names.vue?macro=true";
import { default as indexhgy22y2tBvMeta } from "/home/admin/www/en.pronouns.page/release/20250125130135/pages/nouns/index.vue?macro=true";
import { default as templatesE8Bu9co187Meta } from "/home/admin/www/en.pronouns.page/release/20250125130135/pages/nouns/templates.vue?macro=true";
import { default as peopleDhf7YYdCzgMeta } from "/home/admin/www/en.pronouns.page/release/20250125130135/pages/people.vue?macro=true";
import { default as privacynVGffSuyNYMeta } from "/home/admin/www/en.pronouns.page/release/20250125130135/pages/privacy.vue?macro=true";
import { default as _91username_93DsI9OkEf6pMeta } from "/home/admin/www/en.pronouns.page/release/20250125130135/pages/profile/[username].vue?macro=true";
import { default as card_45_91username_935K91J9ghGYMeta } from "/home/admin/www/en.pronouns.page/release/20250125130135/pages/profile/card-[username].vue?macro=true";
import { default as editorciGHIrIwadMeta } from "/home/admin/www/en.pronouns.page/release/20250125130135/pages/profile/editor.vue?macro=true";
import { default as anyni7yH8xJbnMeta } from "/home/admin/www/en.pronouns.page/release/20250125130135/pages/pronouns/any.vue?macro=true";
import { default as askFkFLSG0Lh9Meta } from "/home/admin/www/en.pronouns.page/release/20250125130135/pages/pronouns/ask.vue?macro=true";
import { default as avoidingbSLrv2q9LlMeta } from "/home/admin/www/en.pronouns.page/release/20250125130135/pages/pronouns/avoiding.vue?macro=true";
import { default as indexwNAC3dJ0BlMeta } from "/home/admin/www/en.pronouns.page/release/20250125130135/pages/pronouns/index.vue?macro=true";
import { default as mirrorEB6YGkB5SHMeta } from "/home/admin/www/en.pronouns.page/release/20250125130135/pages/pronouns/mirror.vue?macro=true";
import { default as pronounTM87Qtqnk2Meta } from "/home/admin/www/en.pronouns.page/release/20250125130135/pages/pronouns/pronoun.vue?macro=true";
import { default as sourcesEGpaJuUS3DMeta } from "/home/admin/www/en.pronouns.page/release/20250125130135/pages/sources.vue?macro=true";
import { default as teamZhRwXFwUMWMeta } from "/home/admin/www/en.pronouns.page/release/20250125130135/pages/team.vue?macro=true";
import { default as terminologyLKr1ppbpEKMeta } from "/home/admin/www/en.pronouns.page/release/20250125130135/pages/terminology.vue?macro=true";
import { default as termsgtnayCV5GuMeta } from "/home/admin/www/en.pronouns.page/release/20250125130135/pages/terms.vue?macro=true";
import { default as userkFvwaQtLr2Meta } from "/home/admin/www/en.pronouns.page/release/20250125130135/pages/user.vue?macro=true";
import { default as workshops6PlxNah1xVMeta } from "/home/admin/www/en.pronouns.page/release/20250125130135/pages/workshops.vue?macro=true";
export default [
  {
    name: "admin-abuseReports",
    path: "/admin/abuse-reports",
    meta: abuseReports8sMqIHd8e0Meta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20250125130135/pages/admin/abuseReports.vue")
  },
  {
    name: "admin-audit-log-username-id",
    path: "/admin/audit-log/:username()/:id()",
    component: () => import("/home/admin/www/en.pronouns.page/release/20250125130135/pages/admin/audit-log/[username]/[id].vue")
  },
  {
    name: "admin-blog-editor",
    path: "/admin/blog/editor",
    component: () => import("/home/admin/www/en.pronouns.page/release/20250125130135/pages/admin/blog/editor.vue")
  },
  {
    name: "admin",
    path: "/admin",
    component: () => import("/home/admin/www/en.pronouns.page/release/20250125130135/pages/admin/index.vue")
  },
  {
    name: "admin-moderation",
    path: "/admin/moderation",
    component: () => import("/home/admin/www/en.pronouns.page/release/20250125130135/pages/admin/moderation.vue")
  },
  {
    name: "admin-pendingBans",
    path: "/admin/pending-bans",
    meta: pendingBans0pyDVnMQYZMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20250125130135/pages/admin/pendingBans.vue")
  },
  {
    name: "admin-profiles",
    path: "/admin/profiles",
    component: () => import("/home/admin/www/en.pronouns.page/release/20250125130135/pages/admin/profiles.vue")
  },
  {
    name: "admin-timesheets-expenses",
    path: "/admin/timesheets/expenses",
    component: () => import("/home/admin/www/en.pronouns.page/release/20250125130135/pages/admin/timesheets/expenses.vue")
  },
  {
    name: "admin-timesheets",
    path: "/admin/timesheets",
    component: () => import("/home/admin/www/en.pronouns.page/release/20250125130135/pages/admin/timesheets/index.vue")
  },
  {
    name: "admin-timesheets-overview",
    path: "/admin/timesheets/overview",
    component: () => import("/home/admin/www/en.pronouns.page/release/20250125130135/pages/admin/timesheets/overview.vue")
  },
  {
    name: "admin-translations-awaiting",
    path: "/admin/translations/awaiting",
    component: () => import("/home/admin/www/en.pronouns.page/release/20250125130135/pages/admin/translations/awaiting.vue")
  },
  {
    name: "admin-translations-missing",
    path: "/admin/translations/missing",
    component: () => import("/home/admin/www/en.pronouns.page/release/20250125130135/pages/admin/translations/missing.vue")
  },
  {
    name: "admin-users",
    path: "/admin/users",
    component: () => import("/home/admin/www/en.pronouns.page/release/20250125130135/pages/admin/users.vue")
  },
  {
    name: "api",
    path: "/api",
    meta: apilBQJDD1AHQMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20250125130135/pages/api.vue")
  },
  {
    name: "blog-slug",
    path: "/blog/:slug()",
    meta: _91slug_93ShjLZNosKLMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20250125130135/pages/blog/[slug].vue")
  },
  {
    name: "blog",
    path: "/blog",
    meta: indexUhdkr9wSsZMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20250125130135/pages/blog/index.vue")
  },
  {
    name: "calendar",
    path: "/calendar/:year?",
    meta: _91_91year_93_93U3nSLG0dXoMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20250125130135/pages/calendar/[[year]].vue")
  },
  {
    name: "calendarDay",
    path: "/calendar/:year()-:month()-:day()",
    meta: _91year_93_45_91month_93_45_91day_93NnsiMpTneUMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20250125130135/pages/calendar/[year]-[month]-[day].vue")
  },
  {
    name: "census-admin",
    path: "/census/admin",
    meta: adminzbsBGL7XHuMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20250125130135/pages/census/admin.vue")
  },
  {
    name: "census",
    path: "/census",
    meta: indexghxKOSlvblMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20250125130135/pages/census/index.vue")
  },
  {
    name: "contact",
    path: "/contact",
    meta: contact1zE4uxC9EOMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20250125130135/pages/contact.vue")
  },
  {
    name: "design",
    path: "/design",
    component: () => import("/home/admin/www/en.pronouns.page/release/20250125130135/pages/design.vue")
  },
  {
    name: "english",
    path: "/english",
    meta: englishrzZR4lRlbxMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20250125130135/pages/english.vue")
  },
  {
    name: "faq",
    path: "/faq",
    meta: faqz3FaN47DC3Meta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20250125130135/pages/faq.vue")
  },
  {
    name: "inclusive",
    path: "/inclusive",
    meta: inclusive93ZqQwai4nMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20250125130135/pages/inclusive.vue")
  },
  {
    name: "index-home",
    path: "/index-home",
    meta: index_45homec33TGfgjZgMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20250125130135/pages/index-home.vue")
  },
  {
    name: "index",
    path: "/index-locale",
    meta: index_45locale9s0XoSTPg5Meta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20250125130135/pages/index-locale.vue")
  },
  {
    name: "langswitch",
    path: "/langswitch",
    meta: langswitchMIlFiFOrWTMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20250125130135/pages/langswitch.vue")
  },
  {
    name: "license",
    path: "/license",
    component: () => import("/home/admin/www/en.pronouns.page/release/20250125130135/pages/license.vue")
  },
  {
    name: "links-academic",
    path: "/links/academic",
    meta: academicrmwLAiqWMpMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20250125130135/pages/links/academic.vue")
  },
  {
    name: "links",
    path: "/links",
    meta: index0XXtVHuE6rMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20250125130135/pages/links/index.vue")
  },
  {
    name: "links-media",
    path: "/links/media",
    meta: media6ffh8iCvibMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20250125130135/pages/links/media.vue")
  },
  {
    name: "links-translinguistics",
    path: "/links/translinguistics",
    meta: translinguisticsW3alNdhmApMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20250125130135/pages/links/translinguistics.vue")
  },
  {
    name: "links-zine",
    path: "/links/zine",
    meta: zineVEwq5gmxdSMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20250125130135/pages/links/zine.vue")
  },
  {
    name: "names",
    path: "/names",
    meta: nameshwUGvpBsW6Meta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20250125130135/pages/names.vue")
  },
  {
    name: "nouns",
    path: "/nouns",
    meta: indexhgy22y2tBvMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20250125130135/pages/nouns/index.vue")
  },
  {
    name: "nouns-templates",
    path: "/nouns/templates",
    meta: templatesE8Bu9co187Meta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20250125130135/pages/nouns/templates.vue")
  },
  {
    name: "people",
    path: "/people",
    meta: peopleDhf7YYdCzgMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20250125130135/pages/people.vue")
  },
  {
    name: "privacy",
    path: "/privacy",
    meta: privacynVGffSuyNYMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20250125130135/pages/privacy.vue")
  },
  {
    name: "profile-username",
    path: "/profile/:username()",
    meta: _91username_93DsI9OkEf6pMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20250125130135/pages/profile/[username].vue")
  },
  {
    name: "profile-card-username",
    path: "/profile/card-:username()",
    meta: card_45_91username_935K91J9ghGYMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20250125130135/pages/profile/card-[username].vue")
  },
  {
    name: "profile-editor",
    path: "/profile/editor",
    meta: editorciGHIrIwadMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20250125130135/pages/profile/editor.vue")
  },
  {
    name: "pronouns-any",
    path: "/pronouns/any",
    meta: anyni7yH8xJbnMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20250125130135/pages/pronouns/any.vue")
  },
  {
    name: "pronouns-ask",
    path: "/pronouns/ask",
    meta: askFkFLSG0Lh9Meta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20250125130135/pages/pronouns/ask.vue")
  },
  {
    name: "pronouns-avoiding",
    path: "/pronouns/avoiding",
    meta: avoidingbSLrv2q9LlMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20250125130135/pages/pronouns/avoiding.vue")
  },
  {
    name: "pronouns",
    path: "/pronouns",
    meta: indexwNAC3dJ0BlMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20250125130135/pages/pronouns/index.vue")
  },
  {
    name: "pronouns-mirror",
    path: "/pronouns/mirror",
    meta: mirrorEB6YGkB5SHMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20250125130135/pages/pronouns/mirror.vue")
  },
  {
    name: "all",
    path: "/:path(.*)",
    meta: pronounTM87Qtqnk2Meta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20250125130135/pages/pronouns/pronoun.vue")
  },
  {
    name: "sources",
    path: "/sources",
    meta: sourcesEGpaJuUS3DMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20250125130135/pages/sources.vue")
  },
  {
    name: "team",
    path: "/team",
    meta: teamZhRwXFwUMWMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20250125130135/pages/team.vue")
  },
  {
    name: "terminology",
    path: "/terminology",
    meta: terminologyLKr1ppbpEKMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20250125130135/pages/terminology.vue")
  },
  {
    name: "terms",
    path: "/terms",
    meta: termsgtnayCV5GuMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20250125130135/pages/terms.vue")
  },
  {
    name: "user",
    path: "/user",
    meta: userkFvwaQtLr2Meta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20250125130135/pages/user.vue")
  },
  {
    name: "workshops",
    path: "/workshops",
    meta: workshops6PlxNah1xVMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20250125130135/pages/workshops.vue")
  }
]
import config from '../data/config.suml';
import abbreviationsRaw from '../data/nouns/abbr.tsv';
import nounDeclensionTemplatesRaw from '../data/nouns/nounDeclension.tsv';
import nounTemplatesRaw from '../data/nouns/nounTemplates.tsv';
import peopleRaw from '../data/people/people.tsv';
import examplesRaw from '../data/pronouns/examples.tsv';
import pronounGroupsRaw from '../data/pronouns/pronounGroups.tsv';
import pronounsRaw from '../data/pronouns/pronouns.tsv';

import { parsePronouns, parsePronounGroups } from './buildPronoun.ts';
import { Example, NounTemplate, PronounLibrary, Person, NounDeclension } from './classes.ts';
import { buildDict, buildList } from './helpers.ts';

export const pronouns = parsePronouns(config, pronounsRaw);
export const examples = buildList(function* () {
    for (const e of examplesRaw) {
        yield new Example(
            Example.parse(e.singular),
            Example.parse(e.plural || e.singular),
            Example.parse(e.null || e.singular),
            Example.parse(e.null_plural || e.plural || e.singular),
            e.isHonorific,
            e.categories
                ? e.categories.split(',').map((c) => c.trim())
                        .filter((c) => c)
                : [],
        );
    }
});
export const nounTemplates = buildList(function* () {
    for (const t of nounTemplatesRaw) {
        yield NounTemplate.from(t);
    }
});
export const pronounGroups = parsePronounGroups(pronounGroupsRaw);

export const pronounLibrary = new PronounLibrary(config, pronounGroups, pronouns);
export const people = buildList(function* () {
    for (const p of peopleRaw) {
        yield new Person(
            p.name,
            p.description,
            p.pronouns.split(','),
            p.sources ? p.sources.split(',') : [],
        );
    }
});
export const nounDeclensionTemplates = buildList(function* () {
    for (const d of nounDeclensionTemplatesRaw) {
        yield new NounDeclension(d);
    }
});

export const abbreviations = buildDict(function* () {
    for (const a of abbreviationsRaw) {
        yield [a.abbreviation, a.meaning];
    }
});

import { useRuntimeConfig } from 'nuxt/app';
import { computed } from 'vue';

import { buildPronoun, buildPronounUsage } from '../src/buildPronoun.ts';
import type { Pronoun, PronounUsage } from '../src/classes.ts';
import { pronounLibrary, pronouns } from '../src/data.ts';
import opinions from '../src/opinions.ts';
import type { Profile } from '../src/profile.ts';
import type { Translator } from '../src/translator';

import useConfig from './useConfig.ts';

export interface PronounOpinion extends PronounUsage {
    link: string;
    opinion: string;
}

export default (profile: Partial<Profile> | null, translator: Translator) => {
    const runtimeConfig = useRuntimeConfig();
    const config = useConfig();
    const pronounOpinions = computed((): PronounOpinion[] => {
        if (!profile) {
            return [];
        }
        return (profile.pronouns ?? [])
            .map(({ value: pronoun, opinion }) => {
                let link = pronoun
                    .trim()
                    .replace(new RegExp(`^${runtimeConfig.public.baseUrl}`), '')
                    .replace(new RegExp(`^${runtimeConfig.public.baseUrl.replace(/^https?:\/\//, '')}`), '')
                    .replace(new RegExp('^/'), '');

                try {
                    link = decodeURIComponent(link);
                } catch {
                    return;
                }

                const usage = buildPronounUsage(pronounLibrary, link, config, translator);
                if (usage) {
                    return {
                        ...usage,
                        link,
                        opinion,
                    };
                }
            })
            .filter((usage) => usage !== undefined);
    });
    const mainPronoun = computed((): Pronoun | null => {
        if (!config.profile.editorEnabled || !config.profile.flags?.defaultPronoun) {
            return null;
        }
        let mainPronoun = buildPronoun(pronouns, config.profile.flags?.defaultPronoun, config, translator);
        let mainOpinion = -1;
        for (const { pronoun, opinion } of pronounOpinions.value) {
            if (!pronoun) {
                continue;
            }
            const opinionValue = opinions[opinion]?.value || 0;
            if (opinionValue > mainOpinion) {
                mainPronoun = pronoun;
                mainOpinion = opinionValue;
            }
        }

        return mainPronoun;
    });
    return {
        pronounOpinions,
        mainPronoun,
    };
};

interface SearchDocumentImage {
    src: string;
    alt?: string;
    class?: string;
}

export interface SearchDocument {
    id: number;
    kind: 'page' | 'pronoun' | 'noun' | 'source' | 'link' | 'faq' | 'blog' | 'term' | 'inclusive' | 'calendar';
    url: string;
    title: string;
    titleSmall?: string | undefined;
    image?: SearchDocumentImage | undefined;
    icon?: string | undefined;
    content: string;
    contentHidden?: string;
    date?: string;
    authors?: string[];
}

export const normaliseQuery = (query: string): string => {
    return query.trim();
};

export const validateQuery = (query: string): 'tooShort' | 'tooLong' | undefined => {
    if (query.length < 3) {
        return 'tooShort';
    }
    if (query.length > 256) {
        return 'tooLong';
    }
};

<script setup lang="ts">
import ClipboardJS from 'clipboard';

import { COLOURS } from '~/src/contact.ts';

// adapted from https://shareon.js.org (MIT)
// can't use from yarn, because window.onload conflicts with SSR

interface ShareData {
    url: string;
    title: string;
    extra: {
        media: string;
        text: string;
        via: string;
    };
}

const NETWORKS = {
    mastodon(d: ShareData) {
        return `https://toot.kytta.dev/?text=${d.title}%0A%0A${d.url}`;
    },
    facebook(d: ShareData) {
        return `https://www.facebook.com/sharer/sharer.php?u=${d.url}`;
    },
    linkedin(d: ShareData) {
        return `https://www.linkedin.com/shareArticle?mini=true&url=${d.url}&title=${d.title}`;
    },
    messenger(d: ShareData) {
        return `https://www.facebook.com/dialog/send?app_id=3619024578167617&link=${d.url}&redirect_uri=${d.url}`;
    },
    odnoklassniki(d: ShareData) {
        return `https://connect.ok.ru/offer?url=${d.url}&title=${d.title}${d.extra.media ? `&imageUrl=${d.extra.media}` : ''}`;
    },
    pinterest(d: ShareData) {
        return `https://pinterest.com/pin/create/button/?url=${d.url}&description=${d.title}${d.extra.media ? `&media=${d.extra.media}` : ''}`;
    },
    pocket(d: ShareData) {
        return `https://getpocket.com/edit.php?url=${d.url}`;
    },
    reddit(d: ShareData) {
        return `https://www.reddit.com/submit?title=${d.title}&url=${d.url}`;
    },
    telegram(d: ShareData) {
        return `https://telegram.me/share/url?url=${d.url}${d.extra.text ? `&text=${d.extra.text}` : ''}`;
    },
    twitter(d: ShareData) {
        return `https://twitter.com/intent/tweet?url=${d.url}&text=${d.title}${d.extra.via ? `&via=${d.extra.via}` : ''}`;
    },
    viber(d: ShareData) {
        return `viber://forward?text=${d.title}%0D%0A${d.url}${d.extra.text ? `%0D%0A%0D%0A${d.extra.text}` : ''}`;
    },
    vkontakte(d: ShareData) {
        return `https://vk.com/share.php?url=${d.url}&title=${d.title}${d.extra.media ? `&image=${d.extra.media}` : ''}`;
    },
    whatsapp(d: ShareData) {
        return `whatsapp://send?text=${d.title}%0D%0A${d.url}${d.extra.text ? `%0D%0A%0D%0A${d.extra.text}` : ''}`;
    },
};

const ICONS: Partial<Record<keyof typeof NETWORKS, string>> = {
    messenger: 'facebook-messenger',
    reddit: 'reddit-alien',
    telegram: 'telegram-plane',
    facebook: 'facebook-f',
    vkontakte: 'vk',
};

const props = withDefaults(defineProps< {
    title?: string;
    networks?: (keyof typeof NETWORKS)[];
    nolabel?: boolean;
    url?: string;
}>(), {
    title: 'Zaimki.pl',
    networks: () => ['mastodon', 'twitter', 'facebook', 'telegram', 'whatsapp', 'messenger'],
});

const runtimeConfig = useRuntimeConfig();
const route = useRoute();
const preset = computed(() => {
    return {
        url: props.url || runtimeConfig.public.baseUrl + route.path,
        title: props.title,
        extra: {
            media: '',
            text: '',
            via: '',
        },
    };
});

const hasShareApi = ref(false);
const clipboard = useTemplateRef('clipboard');
onMounted(() => {
    hasShareApi.value = navigator.share !== undefined;

    new ClipboardJS(clipboard.value!);
});
const shareApi = () => {
    navigator.share({
        url: preset.value.url,
        title: preset.value.title,
        text: preset.value.extra.text,
    });
};

const link = (network: keyof typeof NETWORKS) => {
    return NETWORKS[network](preset.value);
};
const colour = (network: keyof typeof NETWORKS) => {
    return COLOURS[network];
};
const icon = (network: keyof typeof NETWORKS) => {
    return ICONS[network] || network;
};

const justCopied = ref(false);
const copied = () => {
    justCopied.value = true;
    setTimeout(() => justCopied.value = false, 2000);
};
</script>

<template>
    <div>
        <p v-if="!nolabel" class="small mb-0">
            <Icon v="share" />
            <T>share</T><T>quotation.colon</T>
        </p>
        <button v-if="hasShareApi" class="btn btn-primary m-1" @click="shareApi">
            <Icon v="share" />
            <span class="d-none d-md-inline"><T>share</T></span>
        </button>
        <button
            ref="clipboard"
            :class="['btn', justCopied ? 'btn-success' : 'btn-outline-primary', 'm-1']"
            :data-clipboard-text="preset.url"
            :aria-label="$t('crud.copy')"
            :title="$t('crud.copy')"
            @click="copied"
        >
            <Icon :v="justCopied ? 'clipboard-check' : 'clipboard'" />
            <span v-if="!hasShareApi" class="d-none d-md-inline"><T>crud.copy</T></span>
        </button>
        <br>
        <SquareButton v-for="network in networks" :key="network" :link="link(network)" :colour="colour(network)" :aria-label="network">
            <Icon :v="icon(network)" set="b" />
        </SquareButton>
    </div>
</template>

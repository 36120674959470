/* eslint-disable */
const HBS_LATIN_TO_CYRILLIC: Record<string, string> = {
    'A':    'А',
    'a':    'а',
    'B':    'Б',
    'b':    'б',
    'C':    'Ц',
    'c':    'ц',
    'Č':    'Ч',
    'č':    'ч',
    'Ć':    'Ћ',
    'ć':    'ћ',
    'D':    'Д',
    'd':    'д',
    'Dž':   'Џ',
    'dž':   'џ',
    'Đ':    'Ђ',
    'đ':    'ђ',
    'E':    'Е',
    'e':    'е',
    'F':    'Ф',
    'f':    'ф',
    'G':    'Г',
    'g':    'г',
    'H':    'Х',
    'h':    'х',
    'I':    'И',
    'i':    'и',
    'J':    'Ј',
    'j':    'ј',
    'K':    'К',
    'k':    'к',
    'L':    'Л',
    'l':    'л',
    'Lj':   'Љ',
    'lj':   'љ',
    'M':    'М',
    'm':    'м',
    'N':    'Н',
    'n':    'н',
    'Nj':   'Њ',
    'nj':   'њ',
    'O':    'О',
    'o':    'о',
    'P':    'П',
    'p':    'п',
    'R':    'Р',
    'r':    'р',
    'S':    'С',
    's':    'с',
    'Š':    'Ш',
    'š':    'ш',
    'T':    'Т',
    't':    'т',
    'U':    'У',
    'u':    'у',
    'V':    'В',
    'v':    'в',
    'Z':    'З',
    'z':    'з',
    'Ž':    'Ж',
    'ž':    'ж',
};
/* eslint-enable */

export const hbsLatinToCyrillic = (str: string): string => {
    let result = '';
    let i = 0;

    str = str.normalize('NFC');

    while (i < str.length) {
        if (i + 1 < str.length) {
            // check for digraphs
            const digraph = str.substring(i, i + 2);
            if (HBS_LATIN_TO_CYRILLIC.hasOwnProperty(digraph)) {
                result += HBS_LATIN_TO_CYRILLIC[digraph];
                i += 2;
                continue;
            }
        }

        const char = str[i];
        if (HBS_LATIN_TO_CYRILLIC.hasOwnProperty(char)) {
            result += HBS_LATIN_TO_CYRILLIC[char];
        } else {
            result += char;
        }

        i++;
    }

    return result;
};

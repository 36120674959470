<script setup lang="ts">
import { EventLevel } from '../src/calendar/helpers.ts';
import type { Event, Day } from '../src/calendar/helpers.ts';

const props = withDefaults(defineProps<{
    event: Event;
    year?: number;
    range?: boolean;
    ics?: boolean;
    addButton?: boolean;
    removeButton?: boolean;
    month?: boolean;
}>(), {
    year: () => new Date().getFullYear(),
});

const emit = defineEmits< {
    add: [event: string];
    delete: [event: string];
}>();

const config = useConfig();

const eventDays = computed((): Day[] => {
    return props.event.getDays(props.year);
});
const eventName = computed((): string => {
    return props.event.name.split('$')[0];
});
const eventParam = computed((): string | null => {
    return props.event.name.split('$')[1] || null;
});
</script>

<template>
    <component
        :is="event.level === EventLevel.CustomDay ? 'span' : 'nuxt-link'"
        v-if="range"
        :to="calendarDayRoute(eventDays[0])"
        class="badge bg-primary text-white"
    >
        <T v-if="month" :params="{ day: event.getRange(year) }">calendar.dates.{{ eventDays[0].month }}</T>
        <template v-else>
            {{ event.getRange(year) }}
        </template>
    </component>
    <Tooltip v-if="event.level === EventLevel.CustomDay" :text="$t('profile.calendar.customEvents.disclaimer')">
        <Icon :v="event.display.name" />
    </Tooltip>
    <template v-else>
        <Flag
            v-if="event.display.type === 'flag'"
            :alt="$t(`flags_alt.${event.display.name.replace(/'/g, '*').replace(/ /g, '_')}`) || ''"
            :img="`/flags/${event.display.name}.png`"
            :style="event.display.class"
        />
        <Icon v-else :v="event.display.name" />
    </template>
    <T v-if="$te(`calendar.events.${eventName}`, true)" :params="{ param: eventParam! }">calendar.events.{{ eventName }}</T>
    <LinkedText v-else :text="eventName" />
    <a
        v-if="ics && event.level !== EventLevel.CustomDay"
        :href="`/api/queer-calendar-${config.locale}-${year}-${event.getUuid($config.public.baseUrl)}.ics`"
        class="small"
        :aria-label="`${$t('crud.download')} .ics`"
        :title="`${$t('crud.download')} .ics`"
    >
        <Icon v="calendar-plus" />
    </a>
    <small v-if="event.localCalendar">({{ event.localCalendar }})</small>
    <small v-if="event.comment" class="text-muted">{{ event.comment }}</small>
    <a v-if="addButton" href="#" class="text-success" @click.prevent="emit('add', eventName)">
        <Icon v="plus-circle" hover />
    </a>
    <a v-if="removeButton" href="#" class="text-danger" @click.prevent="emit('delete', eventName)">
        <Icon v="minus-circle" hover />
    </a>
</template>

<template>
    <div
        v-if="adConfig?.active"
        :class="[adPlaceholdersVisible ? 'ad-placeholder-preview' : '']"
        :style="adConfig?.sticky ? 'height: 100%' : null"
    >
        <div v-if="adPlaceholdersVisible" :class="[adConfig?.sticky ? 'wrapper-sticky' : '']">
            <p class="text-center h5">
                {{ currentKey }}
            </p>
            <Debug :v="adConfig" />
        </div>
        <div v-else-if="adConfig.fuse" :id="`fuse-${adConfig.slotId}`" :class="['adsbypublift', adConfig?.sticky ? 'wrapper-sticky' : '']" :data-fuse="adConfig.slotId"></div>
        <div v-else-if="adConfig.waldo" :id="`waldo-tag-${adConfig.slotId}`" :class="['adsbynewor', adConfig.video ? 'waldo-video-tag' : '']"></div>
        <ins
            v-else-if="adConfig.adsense"
            class="adsbygoogle"
            style="display:block;margin-block: 1em;"
            data-ad-client="ca-pub-8518361481036191"
            :data-ad-slot="adConfig.slotId"
            :data-ad-format="adConfig.adFormat"
            :data-ad-layout="adConfig.adLayout"
            :data-full-width-responsive="adConfig.responsive ? 'true' : ''"
            role="alert"
            :data-label="$t('support.ad')"
        ></ins>
        <div
            v-else
            :class="['adsinternal', 'text-center', 'py-3', adConfig.display]"
            :data-label="$t('support.ad')"
        >
            <a :href="adConfig.link" target="_blank" rel="noopener">
                <img :src="`/banners/${adConfig.image}`" class="border shadow" :alt="adConfig.alt" style="max-width: 100%">
            </a>
        </div>
    </div>
</template>

<script>
import { mapState } from 'pinia';

import useConfig from '../composables/useConfig.ts';
import adPlaceholders from '../src/adPlaceholders.ts';
import getAdsInternal from '../src/adsInternal.ts';
import { randomItemWeighted } from '../src/helpers.ts';
import { useMainStore } from '../store/index.ts';

const MOBILE_BREAKPOINT = 992;

export default {
    props: {
        phkey: { required: true },
    },
    setup() {
        return {
            config: useConfig(),
        };
    },
    data() {
        return {
            isMobile: undefined,
            adsInternal: getAdsInternal(this.config.locale),
        };
    },
    mounted() {
        (window.adsbygoogle = window.adsbygoogle || []).push({});
        this.handleResize();
        window.addEventListener('resize', this.handleResize);

        this.$nextTick(() => {
            const fusetag = window.fusetag || (window.fusetag = { que: [] });
            const that = this;
            fusetag.que.push(function () {
                fusetag.registerZone(`fuse-${that.adConfig.slotId}`);
            });
        });
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        handleResize() {
            this.isMobile = window.innerWidth < MOBILE_BREAKPOINT;
        },
    },
    computed: {
        currentKey() {
            if (!Array.isArray(this.phkey)) {
                return this.phkey;
            }
            return this.phkey[this.isMobile ? 1 : 0];
        },
        externalAdsEnabled() {
            return this.config.ads && this.config.ads.enabled;
        },
        active() {
            return this.isMobile !== undefined && this.currentKey &&
                (this.adsInternal[this.currentKey] || adPlaceholders[this.currentKey]);
        },
        adConfig() {
            if (!this.externalAdsEnabled && Math.random() < 0.9) {
                return { active: false };
            }

            return this.adConfigPossibilities.length
                ? { active: true, ...randomItemWeighted(this.adConfigPossibilities) }
                : { active: false };
        },
        adConfigPossibilities() {
            if (!this.active) {
                return [];
            }

            let possibilities = [];

            if (this.adsInternal[this.currentKey]) {
                possibilities = possibilities.concat(this.adsInternal[this.currentKey]);
            }

            if (this.externalAdsEnabled) {
                const { slotId, adFormat, adLayout = null, responsive = false, video = false, sticky = false } = adPlaceholders[this.currentKey];
                possibilities.push({
                    chance: 1,
                    fuse: true,
                    slotId,
                    adFormat,
                    adLayout,
                    responsive,
                    video,
                    sticky,
                });
            }

            return possibilities;
        },
        ...mapState(useMainStore, [
            'adPlaceholdersVisible',
        ]),
    },
};
</script>

<style lang="scss">
.ad-placeholder-preview {
    background-color: #b2caec;
    width: 100%;
    height: 200px;
    padding: 1em;
}

.adsbygoogle:not(:empty), .adsinternal:not(:empty), .adsbynewor:not(:empty), .adsbypublift:not(:empty) {
    text-decoration: none;
    &:before {
        content: attr(data-label);
        display: block;
        font-size: 0.7rem;
    }
}
.wrapper-sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 100px;
}
</style>
